/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  Skeleton,
  Textarea,
  User,
  useDisclosure,
  Checkbox
} from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import infoIcon from '@assets/icons/infoicon.svg';
import recognitionIcon from '@assets/icons/recognitionIcon.svg';
import DateUtil from '@utils/date';
import { Form, Formik, FormikProps } from 'formik';
import CreateRecognitionVoteDTO from '../dto/createRecognitionVote.dto';
import CreateRecognitionVoteFormValidation from '../validations/createRecognitionVote.validation';
import VoteUserDTO from '@modules/voteModule/voteUser/dto/voteUser.dto';
import FindOptionsDTO from '@lib/paginated/findOptions.dto';
import VoteUserRepository from '@modules/voteModule/voteUser/voteUser.repository';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faSearch } from '@fortawesome/free-solid-svg-icons';
import StrengthRepository from '../../strength/strength.repository';
import StrengthDTO from '../../strength/dto/strength.dto';
import RecognitionVoteRepository from '../recognitionVote.repository';
import { useSelector } from 'react-redux';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import Input from '@components/input/input';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface RecongnitionCardProps {
  onVote?: () => void;
  open: boolean;
}

const RecongnitionCard = ({
  onVote = () => { },
  open,
}: RecongnitionCardProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [isLoading, setLoading] = useState<boolean>(false);
  const endOfMonth = new Date();
  endOfMonth.setMonth(endOfMonth.getMonth() + 1);
  endOfMonth.setDate(1);
  endOfMonth.setDate(-1);
  const daysDiff = DateUtil.dateDaysDiff(new Date(), endOfMonth);
  const { t } = useTranslation();
  const [strengths, setStrengths] = useState<StrengthDTO[]>([]);
  const [availableRockets, setAvailableRockets] = useState<number>(0);
  const profile = useSelector(selectProfile);
  const [users, setUsers] = useState<VoteUserDTO[]>([]);
  const [userRepository] = useState(new VoteUserRepository());
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [usersHasMore, setUsersHasMore] = useState(true);
  const [usersInputValue, setUsersInputValue] = useState('');
  const [isUsersInputOpen, setIsUsersInputOpen] = useState(false);
  const [strength, setStrength] = useState<StrengthDTO>();

  const initialValues: CreateRecognitionVoteDTO = {
    to: { id: '' },
    because: '',
    rockets: 0,
    strength: { id: '' },
  };

  const getAvailableRockets = async () => {
    setLoading(true);
    const rocketsCount =
      await new RecognitionVoteRepository().getAvailableRockets();
    setAvailableRockets(rocketsCount);
    setLoading(false);
  };

  const getStrengths = async (options?: FindOptionsDTO<StrengthDTO>) => {
    if (options) {
      if (!options?.order)
        options.order = { strength: 'ASC' }
    }
    else options = { order: { strength: 'ASC' } }
    const strengthResult = await new StrengthRepository().find(options);
    setStrengths(strengthResult.results);
  };

  const onSubmit = async (values: CreateRecognitionVoteDTO) => {
    setLoading(true);
    await new RecognitionVoteRepository().vote(values);
    onClose();
    setTimeout(() => {
      onVote();
    }, 500);
    setLoading(false);
    getAvailableRockets();
  };

  useEffect(() => {
    if (open) onOpen();
  }, [open]);

  useEffect(() => {
    getAvailableRockets();
    getStrengths();
  }, []);

  const handleUsersInputChange = (inputValue: string) => {
    setUsersInputValue(inputValue);
    getUsers(PAGINATION_TAKE, inputValue);
  };

  const getUsers = async (
    amount: number = PAGINATION_TAKE,
    searchValue?: string,
  ) => {
    setIsUsersLoading(true);
    const usersResults = await userRepository.find({
      take: amount,
      ...(searchValue
        ? {
          where: {
            fullName: `lk=${searchValue}`,
            externalUser: `!=${profile?.id}`,
          },
        }
        : { where: { externalUser: `!=${profile?.id}` } }),
      order: { fullName: 'ASC' },
    });
    setUsers(usersResults.results);
    if (usersResults.total < amount) setUsersHasMore(false);
    setIsUsersLoading(false);
  };

  useEffect(() => {
    if (isUsersInputOpen) getUsers();
  }, [isUsersInputOpen]);

  const loadMoreUsers = () => {
    const newOffset = users.length + PAGINATION_TAKE;
    getUsers(newOffset, usersInputValue);
  };

  const [, scrollerRef] = useInfiniteScroll({
    hasMore: usersHasMore,
    isEnabled: isUsersInputOpen,
    shouldUseLoader: false,
    onLoadMore: loadMoreUsers,
  });

  return (
    <>
      <Skeleton
        isLoaded={!isLoading}
        className="rounded-xl w-full h-full"
        classNames={{ content: 'h-full' }}
      >
        <Card className="shadow-none w-full h-full bg-content4">
          <CardBody
            className="cursor-pointer w-full flex-row gap-5 p-3 items-center overflow-hidden"
            onClick={() => {
              daysDiff > -1 && availableRockets >= 0 && onOpen();
            }}
          >
            <img src={recognitionIcon} className="size-[90px] lg:size-16 m-0" />
            <div className="flex flex-col justify-between w-full">
              <div className="flex gap-2 items-center">
                <div
                  className={`flex-shrink-0 rounded-full ${daysDiff === -1 || availableRockets === 0
                    ? 'bg-success'
                    : 'bg-warning'
                    } w-2 h-2`}
                ></div>
                <span className="text-default-500 text-sm">
                  {daysDiff === -1 || availableRockets === 0
                    ? t('user.dashboard.surveys.card.alreadyVote')
                    : daysDiff > 1
                      ? t('user.dashboard.surveys.card.dueDate', {
                        days: daysDiff,
                      })
                      : t('user.dashboard.surveys.card.dueDateDay', {
                        days: daysDiff,
                      })}
                </span>
              </div>
              <p className="font-bold">
                {t('user.dashboard.surveys.recognition.title')}
              </p>
              <span className="text-default-500 text-sm overflow-hidden">
                {t('user.dashboard.surveys.recognition.description')}
              </span>
            </div>
          </CardBody>
        </Card>
      </Skeleton>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="5xl"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalBody className="py-5">
            <div className="flex justify-between items-end">
              <div className="flex flex-col gap-1">
                <span className="text-default-400 uppercase">
                  {t('user.dashboard.surveys.recognition.subtitle')}
                </span>
                <span className="text-xl font-bold">
                  {t('user.dashboard.surveys.recognition.thirdtitle')}
                </span>
                <span className="text-default-600"></span>
              </div>
              <div className="bg-primary-100 rounded-3xl flex items-center gap-3 mx-5 px-5 py-3">
                <FontAwesomeIcon
                  icon={faRocket}
                  className="text-primary text-xl"
                />
                <span className="font-bold text-primary">
                  {availableRockets}
                </span>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={CreateRecognitionVoteFormValidation}
              onSubmit={onSubmit}
            >
              {({
                errors,
                setFieldValue,
                values,
              }: FormikProps<CreateRecognitionVoteDTO>) => (
                <Form className="py-5 flex flex-col gap-5">
                  <Autocomplete
                    items={users}
                    onOpenChange={setIsUsersInputOpen}
                    isLoading={isUsersLoading}
                    scrollRef={scrollerRef}
                    inputValue={usersInputValue}
                    onInputChange={handleUsersInputChange}
                    label={t('entities.recognitionVote.fields.to')}
                    startContent={<FontAwesomeIcon icon={faSearch} />}
                    onSelectionChange={(e) => {
                      setFieldValue('to', e ? { id: e } : null);
                      const userSelected = users.find((u) => u.id === e);
                      setUsersInputValue(userSelected?.fullName as string);
                    }}
                    popoverProps={{ placement: 'bottom' }}
                    selectedKey={values.to?.id || null}
                    isInvalid={Boolean(errors.to)}
                    errorMessage={errors.to?.id}
                    color={errors.to ? 'danger' : 'default'}
                    className="w-full"
                    inputProps={{
                      classNames: {
                        inputWrapper:
                          'border-1 border-default shadow-none bg-content4 group-data-[focus=true]:bg-content4 data-[hover=true]:bg-content4',
                      },
                    }}
                  >
                    {(item) => (
                      <AutocompleteItem key={item.id} textValue={item.fullName}>
                        <User
                          name={item.fullName}
                          description={item.email}
                          avatarProps={{
                            src:
                              item.avatar &&
                              `https://giveit-system-assets.s3.amazonaws.com/${item.avatar}`,
                            color: 'secondary',
                          }}
                        />
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <Autocomplete
                      items={strengths}
                      label={t('entities.recognitionVote.fields.strength')}
                      startContent={<FontAwesomeIcon icon={faSearch} />}
                      onSelectionChange={(e) => {
                        setFieldValue('strength', e ? { id: e } : null)
                        const str = strengths.find((s) => s.id === e)
                        if (str) setStrength(str);
                      }}
                      onFocus={() => {
                        getStrengths();
                      }}
                      onInputChange={(e) => {
                        getStrengths({ where: { strength: `lk=${e}` } });
                      }}
                      selectedKey={values.strength?.id || null}
                      isInvalid={Boolean(errors.strength)}
                      errorMessage={errors.strength?.id}
                      color={errors.strength ? 'danger' : 'default'}
                      inputProps={{
                        classNames: {
                          inputWrapper:
                            'border-1 border-default shadow-none bg-content4 group-data-[focus=true]:bg-content4 data-[hover=true]:bg-content4',
                        },
                      }}
                    >
                      {(item) => (
                        <AutocompleteItem
                          key={item.id}
                          textValue={item.strength}
                        >
                          {item.strength}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                    {profile?.group.permissions.some(
                      (permission: { entity: AllowedEntities; methods: AllowedMethods; }) =>
                        permission.entity === AllowedEntities.RECOGNITION &&
                        permission.methods === AllowedMethods.ADMIN
                    ) && strength?.hasConversion ?
                      <>
                        <Input
                          name="conversion"
                          label={t('entities.recognitionVote.fields.conversion')}
                          value={strength.pointsConversion + " "
                            + t('entities.recognitionVote.fields.points') + " "
                            + t('entities.recognitionVote.fields.equalsTo') + " "
                            + strength.rocketsConversion + " "
                            + t('entities.recognitionVote.fields.rocketsConversion')}
                          type="text"
                          disabled={true}
                        />
                        <Input
                          name="points"
                          label={t('entities.recognitionVote.fields.points')}
                          isInvalid={Boolean(errors.points)}
                          errorMessage={errors.points}
                          color={errors.points ? 'danger' : 'default'}
                          onChange={(e) => {
                            setFieldValue('points', e.target.valueAsNumber)
                            if (Number.parseInt(e.target.value) && strength && strength.pointsConversion && strength.rocketsConversion)
                              setFieldValue('rockets', Math.floor((e.target.valueAsNumber / strength.pointsConversion) * strength.rocketsConversion))
                            else
                              setFieldValue('rockets', 0)
                          }
                          }
                          value={String(values.points)}
                          type="number"
                          min={0}
                        />
                        <Input
                          name="rockets"
                          label={t('entities.recognitionVote.fields.rockets')}
                          isInvalid={Boolean(errors.rockets)}
                          errorMessage={errors.rockets}
                          color={errors.rockets ? 'danger' : 'default'}
                          value={String(values.rockets)}
                          type="number"
                          startContent={
                            <FontAwesomeIcon
                              icon={faRocket}
                              className="text-primary"
                            />
                          }
                          disabled={true}
                        />
                      </> :
                      <Input
                        name="rockets"
                        label={t('entities.recognitionVote.fields.rockets')}
                        isInvalid={Boolean(errors.rockets)}
                        errorMessage={errors.rockets}
                        color={errors.rockets ? 'danger' : 'default'}
                        onChange={(e) =>
                          setFieldValue('rockets', e.target.valueAsNumber)
                        }
                        value={String(values.rockets)}
                        type="number"
                        min={0}
                        max={availableRockets}
                        startContent={
                          <FontAwesomeIcon
                            icon={faRocket}
                            className="text-primary"
                          />
                        }
                      />}
                  </div>
                  <Textarea
                    name="because"
                    label={t('entities.recognitionVote.fields.because')}
                    isInvalid={Boolean(errors.because)}
                    errorMessage={errors.because}
                    color={errors.because ? 'danger' : 'default'}
                    onValueChange={(val) => setFieldValue('because', val)}
                    value={values.because}
                    classNames={{
                      inputWrapper:
                        'border-1 border-default shadow-none bg-content4 group-data-[focus=true]:bg-content4 data-[hover=true]:bg-content4',
                    }}
                  />
                  {profile?.group.permissions.some(
                    (permission) =>
                      permission.entity === AllowedEntities.RECOGNITION &&
                      permission.methods === AllowedMethods.ADMIN
                  ) && (<Checkbox
                    isSelected={!values.avoidPostRecognition}
                    onValueChange={() => {
                      setFieldValue('avoidPostRecognition', !values.avoidPostRecognition)
                    }}
                    classNames={{ wrapper: 'dark:before:border-content2' }}
                  >
                    {t('user.dashboard.surveys.recognition.postRecognition')}
                  </Checkbox>)}
                  <Button
                    type="submit"
                    variant="flat"
                    color="primary"
                    isDisabled={isLoading}
                  >
                    {t('user.dashboard.surveys.recognition.send')}
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="flex gap-5 items-start pt-3">
              <img src={infoIcon} />
              <div>
                <p className="font-bold text-default-700 text-lg">
                  {t('user.dashboard.surveys.recognition.why')}
                </p>
                <p className="text-default-500">
                  {t('user.dashboard.surveys.recognition.because')}
                </p>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal >
    </>
  );
};

export default RecongnitionCard;
