import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Button, Checkbox, Input } from '@nextui-org/react';
import CreateStrengthDTO from '../dto/createStrength.dto';
import StrengthFormValidation from '../validation/strength.validation';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface StrengthFormProps {
  initialValues?: CreateStrengthDTO;
  onSubmit: (values: CreateStrengthDTO) => void | Promise<void>;
}

export const initialPulseSurveyFormValues: CreateStrengthDTO = {
  strength: '',
  hasConversion: false
};

const StrengthForm = ({
  initialValues = initialPulseSurveyFormValues,
  onSubmit,
}: StrengthFormProps) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={StrengthFormValidation}
      onSubmit={onSubmit}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateStrengthDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 gap-5">
            <Input
              name="strength"
              label={t('entities.strength.fields.strength')}
              isInvalid={Boolean(errors.strength)}
              errorMessage={errors.strength}
              color={errors.strength ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('strength', e.target.value)}
              value={values.strength}
            />
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.RECOGNITION &&
                permission.methods === AllowedMethods.ADMIN
            ) && (<Checkbox
              isSelected={values.hasConversion}
              onValueChange={() => {
                setFieldValue('hasConversion', !values.hasConversion)
              }}
              classNames={{ wrapper: 'dark:before:border-content2' }}
            >
              {t('entities.strength.fields.hasConversionQuestion')}
            </Checkbox>)}
            {profile?.group.permissions.some(
              (permission) =>
                permission.entity === AllowedEntities.RECOGNITION &&
                permission.methods === AllowedMethods.ADMIN
            ) && values.hasConversion &&
              <>
                <Input
                  name="pointsConversion"
                  label={t('entities.strength.fields.pointsConversion')}
                  isInvalid={Boolean(errors.pointsConversion)}
                  errorMessage={errors.pointsConversion}
                  color={errors.pointsConversion ? 'danger' : 'default'}
                  onChange={(e) => setFieldValue('pointsConversion', Number.parseInt(e.target.value) ? Number.parseInt(e.target.value) : null)}
                  value={values.pointsConversion?.toString()}
                  type='number'
                />
                <p>{t('entities.strength.fields.equalsTo')}</p>
                <Input
                  name="rocketsConversion"
                  label={t('entities.strength.fields.rocketsConversion')}
                  isInvalid={Boolean(errors.rocketsConversion)}
                  errorMessage={errors.rocketsConversion}
                  color={errors.rocketsConversion ? 'danger' : 'default'}
                  onChange={(e) => setFieldValue('rocketsConversion', Number.parseInt(e.target.value) ? Number.parseInt(e.target.value) : null)}
                  value={values.rocketsConversion?.toString()}
                  type='number'
                />
              </>}
          </div>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default StrengthForm;
